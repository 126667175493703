<template>
  <div class='contcatus'>
    <Title :chs="chs" :en="en" :detail="obj" :flag="$route.path" />
  </div>
</template>

<script>

export default {
  data () {
    return {
      chs: '联系我们',
      en: 'contact us',
      obj: {}
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.$axios.get(this.$api.selectEnterpriseInfoDetail).then((res) => {
        this.obj = res.result
      }).catch((err) => {
        this.$message.error(err.desc)
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.contcatus {
  height: 100%;
  background-color: #EEF4FF;

}
</style>
